<template>
  <div class="promote">
    <!-- <div class="ip-line">
      <div>IP address: 150.109.114.218</div>
      <div>ISP: Tencent cloud computing</div>
      <div>Your status:<span class="ip-status"> Unprotected</span></div>
    </div> -->
    <div class="box-1">
      <div class="box-1-left">
        <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/'}`">
          <img alt="logo" src="../assets/image/promote-logo.svg" class="logo" />
        </router-link>
        <div class="count-down">
          <div class="count-down-time-box count-down-hour">
            <div class="count-down-number">0</div>
            <div class="count-down-number count-down-number-left-space">7</div>
          </div>
          <div class="count-down-number-separator">
            <div></div>
            <div></div>
          </div>
          <div class="count-down-time-box count-down-minute">
            <div class="count-down-number">4</div>
            <div class="count-down-number count-down-number-left-space">5</div>
          </div>
          <div class="count-down-number-separator">
            <div></div>
            <div></div>
          </div>
          <div class="count-down-time-box count-down-second">
            <div class="count-down-number">0</div>
            <div class="count-down-number count-down-number-left-space">0</div>
          </div>
        </div>
        <div class="box-1-discount_description">
          {{ $t("promote_get_off") }}
        </div>
        <div class="box-1-guarantee">
          <img
            alt="check"
            src="../assets/image/promote-check.svg"
            class="guarantee-check"
          />
          {{ $t("promote_money_back_guarantee") }}
        </div>
        <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`">
          <button
            type="button"
            class="
              box-1-get-deal-btn
              btn-radius-format-1 btn-color-4 btn-cursor
            "
          >
            {{ $t("promote_get_month_deal") }}
          </button>
        </router-link>
        <div class="download-title">
          {{ $t("promote_download") }}
        </div>
        <div class="download-platform">
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=0'}`"
          >
            <div class="download-box download-box-right-space">
              <img
                src="../assets/icons/header-android.svg"
                class="platform-icon platform-icon-android"
              />
              <span class="platform-name">Android</span>
            </div>
          </router-link>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=1'}`"
          >
            <div class="download-box download-box-right-space">
              <img
                src="../assets/icons/header-windows.svg"
                class="platform-icon platform-icon-windows"
              />
              <span class="platform-name">Windows</span>
            </div>
          </router-link>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=2'}`"
          >
            <div class="download-box">
              <img
                src="../assets/icons/header-mac.svg"
                class="platform-icon platform-icon-mac"
              />
              <span class="platform-name">Mac</span>
            </div>
          </router-link>
        </div>
      </div>
      <img
        alt="promote"
        src="../assets/image/promote-box-1.svg"
        class="box-1-right"
      />
    </div>
    <div class="box-2-title">
      {{ $t("promote_a_great_offer") }}
    </div>
    <div class="box-2">
      <div class="promote-table">
        <div class="column-1">
          <div class="promote-row promote-row-1"></div>
          <div class="promote-row promote-row-w">
            {{ $t("promote_unbeatable_price") }}
          </div>
          <div class="promote-row">{{ $t("promote_streaming") }}</div>
          <div class="promote-row promote-row-w">
            {{ $t("promote_fast_speed") }}
          </div>
          <div class="promote-row">{{ $t("promote_unlimited_bandwidth") }}</div>
          <div class="promote-row promote-row-w">{{ $t("promote_price") }}</div>
        </div>
        <div class="column-2">
          <div class="column-2-bg">
            <div class="column-2-row column-2-row-1">
              <img
                src="../assets/image/promote-logo.svg"
                class="promote-row-logo"
                alt=""
              />
            </div>
            <div class="column-2-row">
              <img
                src="../assets/image/promote-correct.svg"
                class="promote-row-icon"
                alt=""
              />
            </div>
            <div class="column-2-row">
              <img
                src="../assets/image/promote-correct.svg"
                class="promote-row-icon"
                alt=""
              />
            </div>
            <div class="column-2-row">
              <img
                src="../assets/image/promote-correct.svg"
                class="promote-row-icon"
                alt=""
              />
            </div>
            <div class="column-2-row">
              <img
                src="../assets/image/promote-correct.svg"
                class="promote-row-icon"
                alt=""
              />
            </div>
            <div class="column-2-row">$2.03/mo</div>
            <div class="column-2-row extra-row-single-col">
              {{ $t("promote_unbeatable_price") }}
            </div>
          </div>
          <div class="promote-row promote-row-1"></div>
          <div class="promote-row promote-row-w"></div>
          <div class="promote-row"></div>
          <div class="promote-row promote-row-w"></div>
          <div class="promote-row"></div>
          <div class="promote-row promote-row-w"></div>
        </div>
        <div class="promote-column">
          <div class="promote-row promote-row-1">
            <img
              src="../assets/image/promote-other-platform1.svg"
              class="promote-row-logo"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">
            <img
              src="../assets/image/promote-error.svg"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row">
            <img
              src="../assets/image/promote-correct-green.png"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">
            <img
              src="../assets/image/promote-correct-green.png"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row">
            <img
              src="../assets/image/promote-error.svg"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">$2.49/mo</div>
        </div>
        <div class="promote-column">
          <div class="promote-row promote-row-1">
            <img
              src="../assets/image/promote-other-platform2.svg"
              class="promote-row-logo"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">
            <img
              src="../assets/image/promote-error.svg"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row">
            <img
              src="../assets/image/promote-correct-green.png"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">
            <img
              src="../assets/image/promote-correct-green.png"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row">
            <img
              src="../assets/image/promote-correct-green.png"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">$8.82/mo</div>
        </div>
        <div class="promote-column">
          <div class="promote-row promote-row-1">
            <img
              src="../assets/image/promote-other-platform3.svg"
              class="promote-row-logo"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">
            <img
              src="../assets/image/promote-error.svg"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row">
            <img
              src="../assets/image/promote-correct-green.png"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">
            <img
              src="../assets/image/promote-error.svg"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row">
            <img
              src="../assets/image/promote-error.svg"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">$7.99/mo</div>
        </div>
        <div class="promote-column">
          <div class="promote-row promote-row-1">
            <img
              src="../assets/image/promote-other-platform4.svg"
              class="promote-row-logo"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">
            <img
              src="../assets/image/promote-error.svg"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row">
            <img
              src="../assets/image/promote-correct-green.png"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">
            <img
              src="../assets/image/promote-error.svg"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row">
            <img
              src="../assets/image/promote-correct-green.png"
              class="promote-row-icon"
              alt=""
            />
          </div>
          <div class="promote-row promote-row-w">$3.33/mo</div>
        </div>
      </div>
    </div>
    <router-link
      class="box-2-get-deal-btn-container"
      :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
    >
      <button
        type="button"
        class="box-2-get-deal-btn btn-radius-format-1 btn-color-4 btn-cursor"
      >
        Get The Deal
      </button>
    </router-link>
    <div class="box-3">
      <div class="box-3-title">
        {{ $t("promote_feature_you_will_love") }}
      </div>
      <div class="box-3-subtitle">
        {{ $t("promote_experience") }}
      </div>
      <div class="box-3-feature">
        <div class="box-3-feature-item">
          <img
            alt="4k vpn"
            src="../assets/image/promote-4k-Full-HD.svg"
            class="box-3-feature-item-img"
          />
          <div class="box-3-feature-item-text">
            <div class="box-3-feature-item-title">
              {{ $t("promote_ultra_fast_streaming") }}
            </div>
            <div class="box-3-feature-item-subtitle">
              {{ $t("promote_enjoy_fast") }}
            </div>
          </div>
        </div>
        <div class="box-3-feature-item">
          <img
            alt="secure vpn"
            src="../assets/image/promote-secure-payment.svg"
            class="box-3-feature-item-img"
          />
          <div class="box-3-feature-item-text">
            <div class="box-3-feature-item-title">
              {{ $t("promote_top_level_security") }}
            </div>
            <div class="box-3-feature-item-subtitle">
              {{ $t("promote_we_protect_your_privacy") }}
            </div>
          </div>
        </div>
        <div class="box-3-feature-item">
          <img
            alt="vpn support"
            src="../assets/image/promote-support.svg"
            class="box-3-feature-item-img"
          />
          <div class="box-3-feature-item-text">
            <div class="box-3-feature-item-title">
              {{ $t("promote_support") }}
            </div>
            <div class="box-3-feature-item-subtitle">
              {{ $t("promote_day_or_night") }}
            </div>
          </div>
        </div>
        <div class="box-3-feature-item">
          <img
            alt="vpn sales"
            src="../assets/image/promote-rocket.svg"
            class="box-3-feature-item-img"
          />
          <div class="box-3-feature-item-text">
            <div class="box-3-feature-item-title">
              {{ $t("promote_fast_safe") }}
            </div>
            <div class="box-3-feature-item-subtitle">
              {{ $t("promote_supports_all_major_clients") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-4">
      <img
        alt="vpn servers"
        src="../assets/image/promote-map.svg"
        class="box-4-map"
      />
      <div class="box-4-content">
        <div class="box-4-title">
          {{ $t("promote_largest_vpn_server") }}
        </div>
        <div class="box-4-subtitle">
          {{ $t("promote_locations") }}
        </div>
        <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`">
          <button
            type="button"
            class="box-4-btn btn-radius-format-1 btn-color-4 btn-cursor"
          >
            Get The Deal
          </button>
        </router-link>
      </div>
    </div>
    <div class="box-5">
      <div class="box-5-content">
        <div class="box-5-content-title">
          {{ $t("promote_what_people_are_saying_about") }}
        </div>
      </div>
      <div class="box-5-content-comment">
        <Comment />
      </div>
    </div>
    <div class="box-6">
      <div class="box-6-content">
        <div class="box-6-text">
          <div class="box-6-title">
            {{ $t("promote_money_back_guarantee_title") }}
          </div>
          <div class="box-6-subtitle">
            {{ $t("promote_money_back_guarantee_subtitle") }}
          </div>
        </div>
        <img
          alt="vpn guarantee"
          src="../assets/image/promote-guarantee.svg"
          class="box-6-img"
        />
      </div>
    </div>
    <div class="box-7">
      <div class="box-7-title">
        {{ $t("promote_the_final_sale") }}
      </div>
      <div class="box-7-subtitle">
        {{ $t("promote_the_final_sale_subtitle") }}
      </div>
      <div class="box-7-plan-box">
        <div class="box-7-plan-row">
          <PaymentPackage></PaymentPackage>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-tip-box" v-if="isAcceptPrivacyPolicy !== 1">
        <div class="footer-tip">
          By browsing this website, you consent to our -
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/privacy_policy'}`"
            ><span class="text-bold">Privacy Policy</span>
          </router-link>
        </div>
        <button class="footer-tip-btn btn-cursor" @click="acceptPrivacyPolicy">
          <span>Accept</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Comment from "@/components/Comment";
import PaymentPackage from "@/components/PaymentPackage";

export default {
  name: "Promote",
  components: { Comment, PaymentPackage },
  data() {
    return {
      isAcceptPrivacyPolicy: 0,
    };
  },
  methods: {
    acceptPrivacyPolicy() {
      this.isAcceptPrivacyPolicy = 1;
      this.$storage.set("acceptPrivacyPolicy", 1);
    },
  },
  created() {
    this.isAcceptPrivacyPolicy = parseInt(
      this.$storage.get("acceptPrivacyPolicy")
    );
  },
};
</script>

<style scoped>
.promote {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.ip-line {
  background: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #b4b4b4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ip-line div:nth-child(1) {
  margin-right: 65px;
}

.ip-line div:nth-child(2) {
  margin-right: 45px;
}

.ip-status {
  color: #ff0000;
}

.box-1 {
  padding: 34px 16px 102px;
  background: radial-gradient(circle, #002958 0%, #850059 100%);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.box-1-left {
  display: flex;
  flex-direction: column;
}

.logo {
  width: 139px;
  height: 37px;
}

.count-down {
  margin-top: 123px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.count-down-time-box {
  display: flex;
}

.count-down-number {
  width: 54px;
  height: 72px;
  background: #ffffff;
  border-radius: 8px;
  font-size: 57px;
  font-weight: bold;
  line-height: 37px;
  color: #002843;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count-down-number-left-space {
  margin-left: 9px;
}

.count-down-number-separator {
  margin-left: 6px;
  display: flex;
}

.count-down-number-separator {
  display: flex;
  flex-direction: column;
  margin-right: 9px;
  margin-left: 6px;
}

.count-down-number-separator div {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  margin: 9px 0;
}

.box-1-discount_description {
  font-size: 85px;
  font-weight: bold;
  line-height: 103px;
  color: #ffffff;
  margin-top: 30px;
}

.box-1-guarantee {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.guarantee-check {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.box-1-get-deal-btn {
  margin-top: 31px;
  height: 59px;
  padding: 0 50px;
  font-size: 19px;
  display: inline-block;
}

.download-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  margin-top: 74px;
}

.download-platform {
  margin-top: 51px;
  display: flex;
  flex-wrap: wrap;
}

.download-box {
  display: flex;
  align-items: center;
}

.download-box-right-space {
  margin-right: 20px;
}

.platform-icon {
  margin-right: 7px;
}

.platform-icon-android {
  width: 31px;
  height: 36px;
}

.platform-icon-windows {
  width: 35px;
  height: 33px;
}

.platform-icon-mac {
  width: 38px;
  height: 33px;
}

.platform-name {
  font-size: 14px;
  font-weight: 200;
  line-height: 18px;
  color: #ffffff;
}

.box-1-right {
  max-width: 100%;
  height: auto;
}

.box-2 {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  overflow: hidden;
}

@media screen and (max-width: 1260px) {
  .box-2 {
    overflow: scroll;
  }
}

.box-2-title {
  margin-top: 76px;
  font-size: 40px;
  font-weight: bold;
  line-height: 49px;
  color: #1d1d1d;
  text-align: center;
}

.promote-table {
  margin-top: 70px;
  margin-bottom: 119px;
  background: #f1eff9;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  
}

@media screen and (max-width: 1260px) {
  .promote-table {
    align-self: flex-start;
  }
}

.column-1 {
  width: 240px;
  text-align: right;
  box-sizing: border-box;
}

.column-2 {
  width: 270px;
  position: relative;
}

.column-2-bg {
  position: absolute;
  z-index: 1;
  background: rgba(38, 29, 88, 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: -75px;
  border-radius: 0px 0px 20px 20px;
}

.column-2-row {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
}

.column-2-row-1 {
  height: 70px;
}

.promote-column {
  width: 190px;
}

.promote-row {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1d1d1d;
  font-size: 16px;
}

.promote-row-1 {
  height: 70px;
}

.promote-row-w {
  background-color: #fff;
}

.column-1 .promote-row {
  padding-right: 46px;
  justify-content: flex-end;
}

.promote-row-logo {
  height: 28px;
}

.column-2 .promote-row-logo {
  height: 37px;
}

.promote-row-icon {
  height: 15px;
}

.box-2-get-deal-btn-container {
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box-2-get-deal-btn {
  padding: 0 47px;
  height: 59px;
  font-size: 19px;
  font-weight: 400;
}

.box-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 92px 16px 114px;
}

.box-3-title {
  font-size: 40px;
  font-weight: bold;
  line-height: 49px;
  color: #1d1d1d;
  text-align: center;
}

.box-3-subtitle {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #1d1d1d;
  margin-top: 24px;
}

.box-3-feature {
  max-width: 938px;
  background: #ffffff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  margin-top: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 99px 64px 51px;
}

.box-3-feature-item {
  max-width: 450px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 80px;
  flex-wrap: wrap;
}

.box-3-feature-item-img {
  margin-right: 39px;
}

.box-3-feature-item-title {
  font-size: 25px;
  font-weight: 400;
  line-height: 34px;
  color: #1d1d1d;
}

.box-3-feature-item-subtitle {
  max-width: 307px;
  font-size: 17px;
  font-weight: 200;
  line-height: 25px;
  color: #707070;
  margin-top: 9px;
}

.box-4 {
  background: #283730;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 100px 0;
}

.box-4-map {
  max-width: 100%;
  height: auto;
}

.box-4-content {
  margin: 0 14px;
  display: flex;
  flex-direction: column;
}

.box-4-title {
  max-width: 600px;
  font-size: 48px;
  font-weight: 500;
  line-height: 62px;
  color: #ffffff;
}

.box-4-subtitle {
  max-width: 600px;
  font-size: 18px;
  font-weight: 200;
  line-height: 26px;
  color: #ffffff;
  margin-top: 24px;
}

.box-4-btn {
  height: 59px;
  padding: 0 50px;
  font-size: 19px;
  font-weight: 400;
  line-height: 59px;
  margin-top: 40px;
}

.box-5 {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  background: #fafafa;
}

.box-5-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fafafa;
}

.box-5-content-title {
  max-width: 651px;
  font-size: 40px;
  font-weight: bold;
  line-height: 49px;
  color: #1f1f1f;
  margin-top: 68px;
  text-align: center;
}

.box-5-content-comment {
  margin-top: 44px;
  margin-bottom: 55px;
}

.box-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 16px;
}

.box-6-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.box-6-text {
  display: flex;
  flex-direction: column;
}

.box-6-title {
  max-width: 444px;
  font-size: 50px;
  font-weight: bold;
  line-height: 70px;
  color: #1d1d1d;
}

.box-6-subtitle {
  max-width: 343px;
  font-size: 21px;
  font-weight: 400;
  line-height: 36px;
  color: #707070;
}

.box-6-img {
  width: 313px;
  max-width: 100%;
  height: auto;
  margin: 20px 40px;
}

.box-7 {
  background: radial-gradient(circle, #002958 0%, #850059 100%);
  padding: 125px 16px 148px;
}

.box-7-title {
  font-size: 48px;
  font-weight: bold;
  line-height: 60px;
  color: #ffffff;
  text-align: center;
}

.box-7-subtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  margin-top: 24px;
  text-align: center;
}

.box-7-plan-box {
  margin-top: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-7-plan-row {
  display: flex;
  justify-content: center;
}

.footer {
  height: 269px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-tip-box {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  position: fixed;
  /* TODO If add this style, on mobile devices, it doesn't in the center... */
  /* margin: 0 16px; */
  padding: 10px 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  bottom: 51px;
  z-index: 999;
  flex-wrap: wrap;
}

.footer-tip {
  margin: 10px 56px;
  font-size: 16px;
  font-weight: 400;
  color: #3a4d50;
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.footer-tip-btn {
  margin: 10px 56px;
  width: 125px;
  height: 38px;
  background: #00783c;
  border-radius: 10px;
  line-height: 38px;
}

.footer-tip-btn:hover {
  background: #1ba761;
}

.footer-tip-btn span {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}

.extra-row-single-col {
  height: 75px;
}

.box-7-plan-row :deep(.packages-box-btn) {
  color: #3a4d50;
  background: #ffffff;
}

.box-7-plan-row :deep(.packages-box-btn:hover) {
  color: #ffffff;
  background: #c2001a;
}
</style>
