<template>
  <div>
    <el-carousel
      :height="carouselHeight"
      :interval="15000"
      arrow="never"
      indicator-position="outside"
    >
      <el-carousel-item v-for="pageIndex in pageCount" :key="pageIndex">
        <div :ref="setCommentBoxRef" class="comment-box">
          <div
            class="comment-item"
            v-for="(item, index) in pageItems(pageIndex - 1)"
            :key="index"
          >
            <div class="comment-item-top">
              <div class="comment-head">
                <img
                  alt="user comment"
                  :src="item.headUrl"
                  class="comment-head-img"
                />
              </div>
              <div>
                <div class="comment-name">
                  {{ item.name }}
                </div>
                <div class="comment-star">
                  <div
                    class="star-item"
                    v-for="(star, startIndex) in 5"
                    :key="`${'star-item-' + startIndex}`"
                  >
                    <img
                      alt="star"
                      src="../assets/image/star_y.png"
                      class="star-item-icon"
                      v-if="star <= item.star"
                    />
                    <img
                      alt="stars"
                      src="../assets/image/star_n.png"
                      class="star-item-icon"
                      v-else
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="comment-content">
              {{ item.content }}
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: ["commentList", "perPageShowCount"],
  name: "Comment",
  data() {
    return {
      commentBoxRefs: [],
      carouselHeight: "300px",
      currentPerPageShowCount: 3,
      currentCommentList: [
        {
          name: "cox zhu",
          headUrl: require("../assets/image/comment_head1.png"),
          star: 5,
          content: "it's very stable vpn even here in China！",
        },
        {
          name: "kasa7a mi",
          headUrl: require("../assets/image/comment_head2.png"),
          star: 5,
          content:
            "Excellent I use many vpn but this is superb my suggestion is that every one must download this vpn.",
        },
        {
          name: "Vinathi",
          headUrl: require("../assets/image/comment_head3.png"),
          star: 5,
          content:
            "Five-star. This VPN connection is almost difficult to drop after a successful connection, I have set to turn on the automatic start.",
        },
        {
          name: "koko kiki",
          headUrl: require("../assets/image/comment_head4.png"),
          star: 5,
          content:
            "This is the best VPN I have ever used, cheap price and great network status for all servers. I will continue to use it.",
        },
        {
          name: "Daphine Ciara",
          headUrl: require("../assets/image/comment_head5.png"),
          star: 5,
          content:
            "Good speed for China, excellent support for streaming. I can even watch 4K Netflix, HBO Max smoothly.",
        },
        {
          name: "Yu Hui",
          headUrl: require("../assets/image/comment_head6.png"),
          star: 5,
          content:
            "The network of this VPN is really good, and it is worth mentioning that the customer service is very patient and active in helping me to solve my queries.",
        },
        {
          name: "Mclaren",
          headUrl: require("../assets/image/comment_head7.png"),
          star: 5,
          content:
            "This is my second year using ShellVPN and the speed of this vpn is very very good, I would give it five stars, I have ever seen such speed very good.",
        },
      ],
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(
        this.currentCommentList.length / this.currentPerPageShowCount
      );
    },
  },
  created() {
    if (this.perPageShowCount) {
      this.currentPerPageShowCount = this.perPageShowCount;
    }
    if (this.commentList !== undefined && this.commentList.length !== 0) {
      this.currentCommentList = this.commentList;
    }
  },
  mounted() {
    this.setCarouselHeight();
    window.addEventListener("resize", this.setCarouselHeight);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setCarouselHeight);
  },
  methods: {
    pageItems(pageIndex) {
      // console.log(pageIndex);
      const startIndex = pageIndex * this.currentPerPageShowCount;
      let endIndex = startIndex + this.currentPerPageShowCount;
      if (endIndex > this.currentCommentList.length) {
        endIndex = this.currentCommentList.length;
      }
      return this.currentCommentList.slice(startIndex, endIndex);
    },
    setCarouselHeight() {
      setTimeout(() => {
        // console.log(this.commentBoxRefs.length);
        const commentBoxHeight = this.commentBoxRefs[0].clientHeight;
        this.carouselHeight = `${commentBoxHeight + 50}px`;
      }, 50);
    },
    setCommentBoxRef(el) {
      if (el) {
        this.commentBoxRefs.push(el);
      }
    },
  },
  beforeUpdate() {
    this.commentBoxRefs = [];
  },
  updated() {
    // console.log(this.itemRefs)
  },
};
</script>

<style scoped>
.comment-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.comment-item {
  margin: 16px;
  max-width: 347px;
  min-height: 222px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 24px 32px;
  font-size: 14px;
  line-height: 22px;
  color: #2d2d2d;
}

.comment-item-top {
  display: flex;
  margin-bottom: 18px;
}

.comment-head {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  margin-right: 12px;
}

.comment-head-img {
  width: 49px;
  height: 49px;
}

.comment-name {
  font-size: 16px;
  font-weight: 400;
  color: #2d2d2d;
  max-width: 220px;
  height: 19px;
  line-height: 22px;
}

.comment-content {
  max-width: 293px;
}

.comment-star {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.star-item {
  margin-right: 4px;
}

.star-item-icon {
  width: 18px;
  height: 18px;
}
</style>
